<template>
  <div class="footer p-3">
    <div class="h-100 d-flex align-items-center justify-content-between">
      <small class="text-white"
        >© 2021 · MYND DYNAMIC TEAM, INC · ALL RIGHTS RESERVED</small
      >
      <div class="h-100 d-flex align-items-center">
        <a target="_blank" href="https://www.facebook.com/MyndTeam/">
          <b-icon class="h4 mr-3 mb-0" variant="light" icon="facebook"
        /></a>
        <a target="_blank" href="https://www.linkedin.com/company/mynd-consulting">
          <b-icon class="h4 mb-0" variant="light" icon="linkedin" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  height: 58px;
  width: 100%;
  background: #212121;
  .pointer {
    cursor: pointer;
  }
}
</style>