<template>
  <div id="app">
    <NavBar />
    <router-view class="router" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292929;
  height: 100vh;
  .router {
    margin-top: 66px;
  }
}
</style>
