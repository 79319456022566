<template>
  <div class="nav-bar">
    <b-navbar toggleable="md" type="dark" class="shadow-sm" variant="primary">
      <b-navbar-brand tag="div" href="/">
        <b-img fluid :src="headerImage" style="height: 40px" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <!-- <b-navbar-nav>
        <b-nav-item href="#">Link</b-nav-item>
        <b-nav-item href="#" disabled>Disabled</b-nav-item>
      </b-navbar-nav> -->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#home">Home</b-nav-item>
          <b-nav-item href="#story">Mynd's Story</b-nav-item>
          <b-nav-item href="#contact">Contact Us</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import headerImage from "@/assets/header.png";
export default {
  name: "NavBar",
  data() {
    return {
      headerImage,
    };
  },
};
</script>

<style lang="scss">
.nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  a.nav-link {
    color: white !important;
  }
}
</style>