<template>
  <div class="home d-flex flex-column align-items-center">
    <div id="home" class="panel">
      <h2 class="my-5">OUTSOURCING</h2>
      <div class="font-weight-light" style="font-size: 18px">
        We have helped our clients produce world class solutions.
      </div>
      <div class="font-weight-light mb-5" style="font-size: 18px">
        Ultimately successful outsourcing is all about finding someone you can
        trust.
      </div>

      <!-- <b-container class="volunteer">
        <b-link href="https://www.eko.ph/home" target="_blank" class="volunteer-link">
          <b-row class="justify-content-center">
            <b-col xs="12" md="12" lg="10" xl="10">
              <b-container>
                <b-row class="justify-content-center row-vol">
                  <b-col cols="12"
                    ><b-img
                      :draggable="false"
                      class="py-2"
                      fluid
                      :src="top8"
                  /></b-col>
                </b-row>
              </b-container>
              <p class="project-highlight" style="margin-top:10px;">Mynd produced platform to increase social media engagements a thousand-fold.</p>
            </b-col>
          </b-row>
        </b-link>
      </b-container> -->

      <b-container class="top8">
        <b-link
          href="https://www.8list.ph/it-bpo-industry-philippines/"
          target="_blank"
          class="top8-link"
        >
          <b-row class="justify-content-center">
            <b-col xs="12" md="12" lg="10" xl="10">
              <div class="justify-content-center">
                <b-img :draggable="false" class="py-2" fluid :src="top8" />
              </div>
              <h5
                class="top8-highlight font-weight-bold"
                style="margin-top: 10px; margin-bottom: 50px"
              >
                Myrna Padilla, the CEO of Mynd Consulting, was named as one of
                the 8 people in the Philippines who shaped the IT-BPO Industry.
              </h5>
            </b-col>
          </b-row>
        </b-link>
      </b-container>

      <b-container>
        <b-row class="justify-content-center">
          <b-col xs="12" md="12" lg="10" xl="10">
            <div class="d-flex flex-column align-items-center">
              <b-img
                :draggable="false"
                class="w-100 py-2"
                fluid
                :src="emmyImage"
              />
              <p class="project-highlight">
                Mynd produced app nominated for a 2017 Emmy.
              </p>
            </div></b-col
          >
        </b-row>
      </b-container>
      <b-button pill href="#contact" variant="primary" class="my-5"
        >Contact Us</b-button
      >
    </div>

    <!-- SOCIAL  -->
    <div id="social" class="panel">
      <h2>Private Social Networks</h2>
      <div class="font-weight-light">
        City Apps ⋆ Political Campaign Apps ⋆ Employee Apps ⋆ Volunteer Apps
      </div>

      <b-container class="mt-5">
        <b-row>
          <b-col xs="12" md="12" lg="6" xl="6" class="p-0">
            <div class="d-flex flex-column align-items-center">
              <b-img
                :src="socialImage"
                class="social mb-1"
                blank-color="#777"
              />
              <small
                >Since 2016, Mynd has built private social networks for some of
                the <br />
                largest non-profits in the world.</small
              >
              <h3 class="my-5 px-2 text-end">
                Create a private social network to engage your employees,
                members, supporters or volunteers
              </h3>

              <b-button pill href="#contact" variant="primary" class="my-4"
                >Contact Us</b-button
              >
            </div>
          </b-col>
          <b-col xs="12" md="12" lg="6" xl="6" class="d-none d-lg-flex">
            <div class="d-flex flex-column align-items-center">
              <b-img :src="phoneImage" class="phone" blank-color="#777" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--  -->
    <div
      id="story"
      style="min-height: 75vh"
      class="panel text-end w-100 text-white bg-primary p-4 founder"
    >
      <h2 class="my-5">Mynd's Story</h2>
      <b-container>
        <b-row>
          <b-col class="text-center" xs="12" md="12" lg="6" xl="6">
            <!-- <b-img fluid class="w-100 mb-2" :src="myrnaImage" /> -->
            <div class="withvid" style="margin: 0 auto; width: 28vw">
              <iframe
                width="100%"
                height="394"
                src="https://www.youtube.com/embed/6mzwNJZP240"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </b-col>
          <b-col style="text-align: start" xs="12" md="12" lg="6" xl="6">
            <h4 class="about-header">The Human Side of Outsourcing</h4>
            <br />
            <p>
              The Philippine BPO industry has created over 800,000 jobs. What
              does that really mean? It means...
              <br />
              800,000 mothers, fathers, sons and daughters who will stay home in
              the Philippines, instead of being ripped from their families and
              shipped overseas.
            </p>

            <p>
              As a developing country, the Philippines is a nation of migrant
              workers. At any given moment there are over 10 million of us
              working overseas as Overseas Filipino Workers (OFW) or expats. I
              know, because I spent 20 years working overseas to save my own two
              daughters from poverty.
            </p>

            <p>
              When you outsource a job to the Philippines you are doing more
              than just saving money. You are helping transform a developing
              nation. Thank you.
            </p>

            <p>| Myrna Padilla</p>
          </b-col>
        </b-row>
      </b-container>
      <!-- <b-button v-b-modal.myrna-modal pill variant="light" class="my-5"
        >Read More</b-button
      > -->
    </div>

    <!--  -->
    <div
      id="contact"
      class="w-100 position-relative d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div id="map" class="h-100 w-100"></div>
      <b-card class="position-absolute shadow" title="Contact Us">
        <b-container class="mt-0">
          <!-- <b-row>
            <b-col xs="12" md="12" lg="6" xl="6">
              <b-form-input
                v-model="name"
                :disabled="isSending"
                class="mb-2"
                size="lg"
                placeholder="Name"
              />
              <b-form-input
                v-model="email"
                :disabled="isSending"
                class="mb-2"
                size="lg"
                placeholder="Email"
              />
              <b-form-select
                v-model="subject"
                :disabled="isSending"
                class="mb-2"
                size="lg"
                placeholder="Subject"
                :options="subjects"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Subject</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col xs="12" md="12" lg="6" xl="6"
              ><b-form-textarea
                v-model="message"
                :disabled="isSending"
                size="lg"
                placeholder="Message"
                rows="3"
                max-rows="6"
              />
              <b-button
                pill
                variant="primary"
                class="float-right mt-3"
                :disabled="!canSend || isSending"
                @click="send"
              >
                <b-spinner v-if="isSending" small variant="light" />
                <span v-else>Send</span></b-button
              >
            </b-col>
          </b-row> -->
          <b-row>
            <b-col xs="12" md="12" lg="6" xl="6" class="d-flex flex-column">
              <b class="mt-3">Mynd Dynamic Team, Inc.</b>
              <small>
                Door #1-5, Yap Building. <br />
                R. Quimpo Boulevard, <br />
                Ecoland, Davao City 8000 Philippines
              </small>
              <b class="mt-3">Contact</b>
              <small>
                Landline (+63) 82-322-4196<br />
                Mobile (+63) 917-520-3666
              </small>
            </b-col>
            <b-col xs="12" md="12" lg="6" xl="6" class="d-flex flex-column">
              <b class="mt-3">Email Us</b>
              <small>
                <!-- <a
                  @click="openEmail('sales@myndconsulting.com')"
                  class="pointer"
                >
                  sales@myndconsulting.com
                </a>
                <br />
                <a
                  @click="openEmail('resume@myndconsulting.com')"
                  class="pointer"
                >
                  resume@myndconsulting.com
                </a>
                <br /> -->
                <a
                  @click="openEmail('support@myndconsulting.com')"
                  class="pointer"
                >
                  support@myndconsulting.com
                </a>
                <br />
              </small>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
    <b-modal
      id="myrna-modal"
      hide-footer
      size="lg"
      scrollable
      title="Mynd's Story"
    >
      <div class="px-4">
        <div class="pb-2 d-flex">
          <b-img :src="shhhImage" :draggable="false" />
          <div class="d-flex align-items-center">
            <b-img
              style="width: 60px; height: 60px"
              :src="quoteImage"
              :draggable="false"
            />
            <b>Fighting for the Sisters We Left Behind.</b>
          </div>
        </div>
        <div class="font-weight-lighter">
          As a young girl, I was the victim of human trafficking. Today, I own
          an Information Technology business called Mynd Consulting. Along the
          way, I spent 20 years working as a domestic helper and nanny in
          Singapore, Taiwan and Hong Kong and became involved in community
          service by founding the Mindanao Hong Kong Workers Federation.
          <br />
          Today, I am a woman weaving the web. My advocacy is to empower
          millions of the most vulnerable women among us to join in the world’s
          fight against human trafficking and modern day human slavery.
          <br />
          Forgive me for telling such a long story, but to understand my
          advocacy, it will help to understand how I got here and why a woman,
          now empowered with technology, is compelled to fight on behalf of the
          sisters she has left behind.
          <br /><br />
          <b>A woman from the Masa…</b>
          <br />
          <br />
          I am Myrna Y. Padilla, a proud woman of the Masa. I was born to the
          poorest of the poor in a small, undeveloped fishing village on an
          island in the Philippines.
          <br />
          I have only one picture of me and my family when I was a little girl.
          I will attach it to this post. We are standing in front of a small
          bamboo hut. The hut was my childhood home and located just a few
          meters away from the Bohol Sea. I am standing to the left of my mother
          and wearing one of the finest dresses I have ever owned. It was made
          with a mother's love from a flour bag bleached white.
          <br /><br />
          <b>My childhood…</b>
          <br />
          <br />
          Some of my earliest memories are the tears in my mother's eyes when we
          would go to bed hungry. I know from personal experience the ugly face
          of poverty. By the time I was 10 years old, to help feed my family, I
          was diving alone in the sea to hunt for fish using a spear gun made by
          my father from an old umbrella. For money, I would dive for seaweed to
          sell in the market in the nearby town of Loboc. When we would go into
          town to sell our seaweed, the old women would tease me. I still hear
          them saying, no one would ever marry such an ugly little girl. Diving
          in the sea under the hot sun made me so dark. And bathing in the salt
          water would leave white patches on my skin and frizzle my hair. I was
          pitiful looking. Out of respect for my elders, I never fought with the
          old women of the market stalls. Instead, I learned to make them smile
          by singing and talking and joking with them. Over the years, I gained
          their respect and by the time I was a young teenager, I was even
          called upon to speak on their behalf. The town children were like
          children everywhere. They can be so cruel to those who are different
          from themselves. And we were different. We were village people. Even
          though they were poor, we were the poorest of the poor. As the eldest,
          it soon became my job to protect my younger sisters. There was no
          singing. I learned to fight. And the town boys soon learned, I was
          deadly with a rock at 20 meters. And, believe it or not, by the time I
          was a young woman, some of those town boys proclaimed their love for
          me.
          <br /><br />
          <b>Beautiful lies.</b>
          <br />
          <br />
          There was only one way for a child to sell seaweed in the market
          without the capital for a stall, you had to be a street vendor. A
          street vendor cannot be shy. You have to be fearless. I learned to
          talk with strangers. Make them smile. Sing them a song. I soon learned
          that I could make more money singing than selling seaweed. I started
          singing in competitions at local festivals.
          <br />
          It was after one of these festivals that I was approached by a
          “Recruiter” from Manila. She told me the same story that a thousand
          young girls have been told. She told me I was beautiful. She told me I
          was special. She told me I could get a job singing in Japan. She told
          me such beautiful lies.
          <br />
          Finally, she told me something I wanted desperately to believe. She
          told me I could help save my family from poverty. She gave me hope and
          a dream and I found myself believing in her with all my heart. Because
          I had nothing to wear suitable for the city, my father gave me his
          best pair of pants and his best shirt. They were far too big for me,
          but I felt so safe wrapped inside them. I went with her to Manila
          wrapped in my father’s clothes.
          <br /><br />
          <b>I was a slave...</b>
          <br />
          <br />
          In Manila, I soon discovered I had misunderstood everything. Before
          she could arrange an audition for me to go overseas and sing in Japan,
          I had to first pay off “my debt”. She had gone through so much trouble
          to get me there. There were transportation costs. And “recruitment”
          fees. And singing lessons.And she had to feed me.
          <br />
          At that moment, I thought I understood everything. I reached out and
          took her hand, bowed and gently place it to my forehead in the
          traditional of my tribe as a sign of respect. Then I kissed her hand
          softly and vowed to be the hardest worker she ever had. I would repay
          my debt. I was so deeply grateful and I was so naïve.
          <br />
          She had given me hope and a powerful dream. I would save my family
          from poverty. I spent the next year working as a slave (there is no
          other word for it) washing cloths by hand. Twelve hours a day. Seven
          days a week. No day off. No pay. No singing lessons.
          <br />
          They never beat me, only because they quickly learned I would fight
          back. And I was obedient and never complained. The hard work meant
          nothing to me. I had long become use to it. I practiced my singing
          while I washed the clothes and I dreamed.
          <br />
          Soon I was joined by a young girl named Ling Ling. Four years younger
          than me. She was from the mountains above my fishing village and we
          spoke the same dialect. We became best friends. We laughed so much.
          And night after night we shared our dreams. I would become a singer in
          Japan. I would save my family. No one would ever go to bed hungry
          again.
          <br /><br />
          <b>The power of hope...</b>
          <br />
          <br />
          As strange as it may sound, I never felt despair. In the years since,
          I have often wondered how a slave could have joy in her heart. I now
          know why and it has become a core part of my advocacy. In the
          Philippines, we call it “pag-asa”. In English you call it hope. When
          you give people hope, even a slave can sing.
          <br />
          After almost a year, my chance finally came. The recruiter had set up
          an audition. Ling Ling wished me luck. For the first time in almost a
          year, I walked out of the compound.
          <br />
          We went to a nearby building and through a back door. A young Filipino
          makeup artist was waiting for me. He fixed me up and had me dress in a
          beautiful gown. But there was no bra and my bare back was showing. I
          was so uncomfortable, but he assured me everything was ok. I was an
          entertainer.
          <br />
          My recruiter led me out onto a small stage surrounded by a group of
          men. She explained the men were my “judges”. Even though I had never
          seen the inside of a nightclub, I knew in my heart, something was
          wrong. But I wanted so badly to believe in dreams that I took a deep
          breath and sang my heart out for them. I sang Frank Sinatra’s “My
          Way”.
          <br />
          Half way through my song, one of my “judges” came up on stage. He ran
          his hand down my bare back. I knew at that moment that my dream of
          saving my family from poverty was lost. I knew then exactly what was
          expected of me and I did what came natural.
          <br /><br />
          <b>I slapped him...</b>
          <br />
          <br />
          And it was not a little girl’s slap. It was a hard woman's slap
          delivered with the force of a girl whose first job was pounding rice
          at the age of nine. It was the hard slap of a girl who had been diving
          alone in the sea forever. It was the hard slap of a slave who had been
          washing laundry seven days a week for almost a year.
          <br />
          It was a slap that stopped the music and sent the old man staggering;
          sent my recruiter screaming and sent me running. I grabbed my father’s
          clothes, ran back to the compound. Said goodbye to Ling Ling and ran
          away.
          <br /><br />
          <b>My miracle...</b>
          <br />
          <br />
          I found my way to an old church in Baclaran, where I spent my first
          night free, broke and homeless. And for the first time since coming to
          Manila, I cried. And for the first time in my life, I felt worthless.
          When you are born to the poorest of the poor you stop crying very
          early in life. Everything is hard. Life is hard. But this was
          different. When you are poor, it is rare to have a dream you can
          almost touch. That night I cried hard for a shattered dream.
          <br />
          While I sat there in the church crying, a young woman came to me. She
          could tell I was not from Manila by the way I was dressed. She sat
          down by my side and began to comfort me. Her name was Ponyang and she
          was a domestic helper. She cried with me.
          <br />
          After sharing my story with her, she suddenly stopped me; she took
          both my hands and forced me to look into her eyes. She gave me the
          name of a bakery and told me exactly what I needed to say and do to
          get a job. We both stopped crying and started laughing. It would work.
          I knew it would. Ponyang, the domestic helper, had given me hope
          again.
          <br />
          That morning, I went to the bakery and declared to the bakery owner,
          “I will be the best street vendor you ever had.” Then, instead of
          shouting out “pandesal” (bread) like the other vendors do, I sang it
          out as loud and as beautifully as I could. He laughed so hard, but
          hired me on the spot.
          <br />
          I walked the streets of Manila along Airport Road with a basket of
          bread on my head, wrapped in my father’s clothes and dodging my old
          recruiter and her boys. The job was no different than selling seaweed
          in the market back home. I was good at it and I did become the best
          street vendor that bakery ever had.
          <br />
          A few days after escaping, my friend Ling Ling found me. She had been
          badly abused. They had forced a hot boiling spoon of rice into her
          mouth as punishment. I refused to let her go back. I helped her find a
          place to live until she could return to her family.
          <br />
          Looking back on it now, my advocacy began that day. And now that I am
          older, I shudder at the thought of what could have been. My heart
          breaks at the thought of the millions of girls who have “recruiters”
          far more brutal than mine and who do not have the option of fighting .
          I was so lucky.
          <br />
          I now know the Church of Baclaran, where I met Ponyang, was open that
          night to serve the workers and patrons of the red light district. It
          is known as the Church of Miracles.
          <br />
          My miracle was to be saved by a simple domestic helper who took pity
          on someone less fortunate than herself. Ponyang’s small act of
          kindness saved me from despair. Her character and willingness to help
          someone less fortunate than herself, despite her lowly position, was
          far nobler than most I have known.
          <br />
          My next transformation was a deeply painful one…
          <br />
          Without getting into details, I eventually found myself in a failed
          marriage to a good man. We were both victims of our traditions and I
          suffered from the violence imposed on an independent woman who never
          learned her place.
          <br />
          From that marriage, I was blessed with two beautiful baby girls. Any
          dreams I had for myself were quickly replaced by dreams for them. Even
          though I had never graduated high school, I knew the path out of
          poverty for my two girls was an education. My dream for my two girls
          was that they would one day graduate college.
          <br />
          I knew I could not fulfil that dream working as a street vendor. So,
          like many other women born into poverty in the Philippines, my only
          chance to fulfil those dreams was to go overseas to seek employment.
          <br />
          I became a migrant worker, what is known in the Philippines, as an
          Overseas Filipino Worker (OFW). The Philippines is a nation of OFWs.
          There are over ten million of us scattered all over the world. Ten per
          cent of our population work abroad.
          <br />
          I travelled first to Singapore, then Taiwan and eventually Hong Kong.
          I will not focus on the indignities and the abused heaped upon women
          in my position working as a domestic helper. I have slept on unheated
          kitchen floors in the dead of winter and eaten scraps fit only for a
          dog.
          <br />
          But no abuse, verbal or otherwise, remotely compares to the pain and
          anguish of a mother separated from her children. That is the terrible
          price millions of women have paid to save our children from poverty.
          Poverty is a cruel and harsh master.
          <br />
          Community Service, Technology and a Chinese boy…
          <br />
          After almost 12 years of working overseas, I finally found a boss in
          Hong Kong who was kind to me. Over the years, I earned the family’s
          trust and respect, and in return, they earned my undying loyalty
          forever. I became part of that family.
          <br />
          They entrusted me to raise their son. His name was Jonathan. He had
          just got a new computer and part of my responsibility was to make sure
          he did his homework and not play games. It was young Jonathan, an
          eight year old Chinese boy, who taught me how to use a computer.
          <br />
          The empowering nature of technology…
          <br />
          It was also while working for this family that I became active in
          community service. In 1999, I founded the Mindanao Hong Kong Workers
          Federation (MinFed) and I was fortunate to serve as Chairman for 8
          years. Eventually I began applying technology to the operation of
          MinFed.
          <br />
          However, it was when I began using the internet that I began to fully
          understand and appreciate that people could perform services without
          the need to leave home.
          <br />
          For those of you who have never left your children behind for 5, 10 or
          15 years you have no idea how powerful a concept that is. I was a
          transformed woman.
          <br /><br />
          <b>I started dreaming…</b>
          <br />
          <br />
          In Hong Kong, the Mindanao Hong Kong Worker’s Federation would gather
          in the park on Sunday. Our only day off.
          <br />
          There, we would conduct our meetings and part of my responsibility as
          a leader was to listen to my members, listen to their complaints and
          listen to their stories.
          <br />
          Some of those stories broke my heart.
          <br />
          I cannot count the number of nights I lay in bed crying. Sometimes I
          cried for myself and other times I cried for my members.
          <br />
          But because of the empowering nature of technology, I started
          dreaming. I had finally accomplished the task fate had set before me
          as a mother. Both my girls had graduated college, so now the dreams
          were mine.
          <br />
          I dreamed of coming home and starting a business. I dreamed of
          becoming a job creator. I simply did not want another mother or father
          to follow in my footsteps and suffer the same pain and anguish of
          being separated from their families.
          <br />
          I now know I was envisioning Business Process Outsourcing (BPO)
          without even knowing what a BPO was.
          <br /><br />
          <b>Mynd Consulting</b>
          <br />
          <br />
          To make a very long story short, in 2006, I returned home to the
          Philippines after almost 20 years and founded a BPO in Davao called
          Mynd Consulting. We started with two people and an old computer
          offering bug testing and QA services to US clients.
          <br />
          Today, my small company runs multiple shifts supplying virtual teams
          of programmers on a long term basis to clients in both the US and
          Europe.
          <br /><br />
          <b>Job creator...</b>
          <br />
          <br />
          Now that you know my background, you can appreciate I view business
          from a very different perspective than most. First and foremost, I see
          myself as a job creator and secondly, I want to find ways to do both
          good and good business at the same time.
          <br />
          I am also fortunate to have stumbled into the IT-BPO industry in the
          Philippines. Others talk about our industry and how we are responsible
          for creating over 800, 000 jobs over the last few years. I have to
          share with you what that really means to me.
          <br />
          To me it means 800,000 mothers, fathers, sons or daughters who will
          now stay home in the Philippines, instead of being ripped from their
          families and shipped overseas. I am so proud of the small part I have
          played in that.
          <br />
          I know there are people overseas who see outsourcing as a threat and I
          wish I could make them understand. The jobs that are outsourced to the
          Philippines are helping transform a nation.
          <br />
          What success has meant for me…
          <br />
          First and foremost, aside from any financial considerations, success
          has meant fulfilling my dream of becoming a job creator. A goal I set
          for myself so many years ago in Hong Kong.
          <br />
          My company is very small, but the employees I have are working for
          bosses scattered all over the world, but at the end of the day, my
          people go home to be with their families.
          <br />
          Unless you have been forced to leave your children behind for 20
          years, it may be hard to imagine how rewarding that is for me.
          <br />
          However, no amount of success in business will erase the 20 years of
          memories I have as a migrant worker. As a woman, I was not always
          treated with the respect and dignity we all deserve.
          <br />
          No amount of success will erase the stories of abuse I have heard and
          witnessed.
          <br />
          And no amount of success will allow me to forget, at this very moment,
          there are millions of women who have fallen victim to abusive
          employers, human trafficking and modern day human slavery.
          <br />
          These women are desperately crying out for help, but no one hears
          them. My small success in business has given me an opportunity to do
          something about it.
          <br /><br />
          <b>Hope...</b>
          <br />
          <br />
          Using the profits from my company, we are funding a project called OFW
          Watch. It uses social media and mobile technology to empower the most
          vulnerable women among us to join in the world’s fight against human
          trafficking and modern day human slavery.
          <br />
          There are over 212 million migrant workers worldwide. Over 50 million
          of these are women employed as domestic workers and nannies. Although
          millions of these women have disproportionately fallen victim to human
          trafficking and modern day human slavery, there are millions who are
          the exact opposite of helpless.
          <br />
          Millions of migrant workers have succeeded and have become masters of
          social media and mobile technology to communicate with family back
          home. I know for a certainty, if we can reach out to these women and
          ask them to volunteer to help those less fortunate than themselves,
          they will do so. I know because we have built such a network and have
          quietly registered over 14,000 volunteers scattered all over the
          world.
          <br />
          When we use technology, combined with community service, to engage
          these women in the fight against human trafficking, we are helping the
          women who volunteer every bit as much, as the victims they may save.
          The very act of the world asking for their help will make them smile
          and if we can successfully engage them in community service then will
          have given their lives new meaning that goes beyond the floors they
          mop or the toilets they clean.
          <br />
          Our vision is for the network we create to go beyond the fight against
          human trafficking. We will learn how to use mobile technology to
          deliver something so desperately needed. Hope.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import emmyImage from "@/assets/emmy_updated.png";
import top1Image from "@/assets/top_1.jpg";
import top2Image from "@/assets/top_2.jpg";
import myrnaImage from "@/assets/myrna.png";
import shhhImage from "@/assets/shhh.png";
import quoteImage from "@/assets/quote.png";
import socialImage from "@/assets/social.png";
import phoneImage from "@/assets/phone.png";
import engageko from "@/assets/engageko_logo.png";
import top8 from "@/assets/8_people.jpg";

export default {
  name: "Home",
  data() {
    return {
      top8,
      engageko,
      emmyImage,
      top1Image,
      top2Image,
      myrnaImage,
      shhhImage,
      quoteImage,
      socialImage,
      phoneImage,
      isSending: false,
      subject: null,
      emailTo: "sales@myndconsulting.com",
      name: "",
      email: "",
      message: "",
      subjects: ["Sales", "Resume", "Support", "Other"],
      mapCenter: {
        lat: 7.048024027571204,
        lng: 125.60844209441294,
      },
      myndLocation: {
        lat: 7.052162,
        lng: 125.590792,
      },
    };
  },
  computed: {
    hash() {
      return this.$router.hash;
    },
    canSend() {
      return !!this.name && !!this.email && !!this.subject && !!this.message;
    },
  },
  watch: {
    hash(v) {
      setTimeout(() => {
        const yOffset = 966;
        const element = document.getElementById(v);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }, 2000);
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadMap();
    }, 1000);
  },
  methods: {
    send() {
      this.isSending = true;
      var subject = `#${this.subject} from Myndconsulting by (${this.name})`;
      var message = this.email + "\n" + this.message;

      this.$axios
        .post("https://api.myndconsulting.com/v1/api/mail", {
          from: this.email,
          content: message,
          title: subject,
        })
        .then(() => {
          this.subject = "";
          this.name = "";
          this.email = "";
          this.message = "";
          this.isSending = false;
          this.$bvToast.toast("Message sent !", {
            toaster: "b-toaster-top-center",
            bodyClass: "d-flex justify-content-center",
            solid: true,
            noCloseButton: true,
            variant: "success",
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    loadMap() {
      var map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: this.mapCenter,
        disableDefaultUI: true,
        zoomControl: true,
      });
      new window.google.maps.Marker({
        position: this.myndLocation,
        map: map,
      });

      // used for detecting center of map, uncomment for logs
      // map.addListener("dragend", () => {
      //   let a = {
      //     lat: map.center.lat(),
      //     lng: map.center.lng(),
      //   };
      //   console.log("xxx", a);
      // });
    },
    openEmail(email) {
      window.open(`mailto:${email}`, "_blank");
    },
    openPhone(phone) {
      window.open(`tel:${phone}`, "_self");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .social {
    width: 75%;
  }
  .phone {
    width: 420px;
  }

  @media only screen and (max-width: 600px) {
    .social {
      width: 100%;
    }
  }
}
</style>
